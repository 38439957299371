
import { Component, Watch } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import PostCard from '../../../components/cards/postCard.vue';
import AssetCard from '../../../components/cards/assetCard.vue';
import CampaignSidebar from '../../../components/campaign-sidebar.vue';
import { Route } from 'vue-router';
import { CampaignModule } from '@/store';
import { getModule } from 'vuex-module-decorators';
import { CampaignCategory } from '@/models/campaigns/campaignCategory';
import { CampaignDetail } from '@/models/campaigns/campaignDetail';
import { format } from 'date-fns';
import VideoCard from '@/components/cards/videoCard.vue';
import RadioCommercialCard from '@/components/cards/radioCommercialCard.vue';
import { PostListItem } from '@/models/posts/postListItem';
import { AssetListItem } from '@/models/assets/assetListItem';
import { CampaignAssetsResponse } from '@/api/contracts/campaigns/campaignAssetsResponse';
import { UserStore } from '@/models/users/userStore';
import { AltDrawerType } from '@/constants';

const campaignModule = getModule(CampaignModule);

@Component({
  components: {
    RadioCommercialCard,
    VideoCard,
    AssetCard,
    PostCard,
    CampaignSidebar,
  },
})
export default class CampaignDetailView extends LoggedInComponentBase {
  public sortByDateEnabled: boolean = false;

  get campaignAssetsResponse(): CampaignAssetsResponse {
    return campaignModule.campaignAssetsResponse;
  }

  get campaignAssets(): Array<AssetListItem> {
    return this.campaignAssetsResponse.data;
  }

  public get brandName(): string {
    return this.storesModule.currentBrand.name;
  }

  get channels(): Array<string> {
    const result: Array<string> = [];
    if (this.posts?.length) {
      result.push('Posts;');
    }
    if (this.campaignAssets.length) {
      this.campaignAssets.forEach((asset) => {
        if (asset.isVideoAsset) {
          result.push('Videos;');
        } else {
          if (!result.includes('Images;')) {
            result.push('Images;');
          }
        }
      });
    }
    return result;
  }

  get currentCampaignCategory(): CampaignCategory {
    return campaignModule.currentCampaignCategory;
  }

  get campaignDetail(): CampaignDetail {
    return campaignModule.campaignDetail;
  }

  public get currentStore(): UserStore {
    return this.userModule.currentStore;
  }

  get assets(): Array<AssetListItem> {
    return this.campaignAssets.filter((asset) => !asset.videoCode); // update to !asset.isYoutubeVideo when api fixed
  }

  get posts(): Array<PostListItem> {
    const sortedByDate = [...campaignModule.campaignPosts];
    if (this.sortByDateEnabled) {
      sortedByDate?.sort(
        (a: PostListItem, b: PostListItem) =>
          new Date(a.suggestedPublishDateTime).valueOf() -
          new Date(b.suggestedPublishDateTime).valueOf()
      );
    }
    return sortedByDate;
  }

  get formattedDuration(): string {
    const startDate: Date = new Date(this.campaignDetail.startDateTime);
    const endDate: Date = new Date(this.campaignDetail.endDateTime);
    const campaignStartMonth: string = startDate.toLocaleString('default', {
      month: 'long',
    });
    const campaignEndMonth: string = endDate.toLocaleString('default', {
      month: 'long',
    });

    const st = format(startDate, 'do'),
      end = format(endDate, 'do');

    if (campaignEndMonth !== campaignStartMonth) {
      return `${st} ${campaignStartMonth} to ${end} ${campaignEndMonth}`;
    } else {
      if (st !== end) {
        return `${st} to ${end} ${campaignEndMonth}`;
      }
      // 1 day only campaign
      return `${st} ${campaignEndMonth}`;
    }
  }

  get radioCommercials(): Array<AssetListItem> {
    //TODO: Update when we have radio commercials.
    return [];
  }

  get tvcs(): Array<AssetListItem> {
    return this.campaignAssets.filter((asset) => !!asset.videoCode); // update to asset.isYoutubeVideo when api fixed
  }

  async created() {
    await this.getCampaignAssets();
    await this.getCampaignPosts();
  }

  mounted() {
    this.updateSidebarStates();
  }

  public async beforeRouteUpdate(
    to: Route,
    from: Route,
    next: (arg?: boolean | ((vm: CampaignDetailView) => void)) => void
  ): Promise<void> {
    try {
      campaignModule.setCurrentCampaignCategory(to.params.category);
      await campaignModule.getCampaignDetail(to.params.id);
      await this.getCampaignPosts(to.params.id);
      await this.getCampaignAssets(to.params.id);
      next();
    } catch (err) {
      console.error(err.message);
      next(false);
    }
  }

  public async beforeRouteEnter(
    to: Route,
    from: Route,
    next: (arg?: boolean | ((vm: CampaignDetailView) => void)) => void
  ): Promise<void> {
    try {
      await campaignModule.getCampaignCategories();
      // Set the campaign category so we can use it in the breadcrumb navigation.
      campaignModule.setCurrentCampaignCategory(to.params.category);
      await campaignModule.getCampaignDetail(to.params.id);
      next();
    } catch (err) {
      console.error(err.message);
      next(false);
    }
  }

  public async getCampaignAssets(nextCampaignId?: guid) {
    return await campaignModule.getCampaignAssets({
      state: this.currentStore ? this.currentStore.state : '',
      campaignId: nextCampaignId || this.$route.params.id,
      pagination: {
        limit: 20,
        start: 0,
        sort: '',
      },
    });
  }

  private async getCampaignPosts(nextCampaignId?: guid) {
    return await campaignModule.getCampaignPosts({
      id: nextCampaignId || this.$route.params.id,
      state: this.currentStore ? this.currentStore.state : '',
    });
  }

  public updateSidebarStates() {
    if (this.$vuetify.breakpoint.smAndDown) {
      if (this.uiModule.upcomingPostsSidebarOpen) {
        this.uiModule.toggleAltDrawer({
          drawerType: AltDrawerType.UpcomingPostsSidebar,
          open: false,
        });
      }

      this.uiModule.setNavigationDrawer(false);
    } else {
      this.uiModule.toggleAltDrawer({
        drawerType: AltDrawerType.UpcomingPostsSidebar,
        open: true,
      });
      this.uiModule.setNavigationDrawer(true);
    }
  }

  @Watch('brandName')
  onBrandChange() {
    this.$router.replace({ name: 'home.root' });
  }
}
