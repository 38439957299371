
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { Component, Prop } from 'vue-property-decorator';
import { format } from 'date-fns';
import { getPostChannels } from '@/services/eventFactory';
import cloudinaryImageResize from '@/helpers/cloudinary-image-resize';

// types
import { PostListItem } from '@/models/posts/postListItem';
import { EventTypes, EventTypesLabels } from '@/constants';

@Component
export default class PostCard extends LoggedInComponentBase {
  @Prop() post!: PostListItem;

  public get isAutomatedPost(): boolean {
    return this.post.postType === EventTypesLabels.AutomatedPosts;
  }

  public get postChannels(): string[] {
    return getPostChannels(this.post);
  }

  public get formattedPostDate(): string {
    if (!this.post.suggestedPublishDateTime) return '';
    const postDate: Date = new Date(this.post.suggestedPublishDateTime);
    return `${format(postDate, 'd')}/${format(postDate, 'M')}/${format(
      postDate,
      'yyyy'
    )}`;
  }

  public get imageUrl() {
    return cloudinaryImageResize(this.post.asset?.blobUrl!, 400);
  }

  public get videoSrc() {
    return this.post.asset?.blobUrl;
  }

  public get videoThumb() {
    return this.post.asset?.videoThumbnail;
  }

  public get isVideoAsset() {
    return !!this.post.asset?.isVideoAsset && !this.post.asset.isYoutubeVideo;
  }

  public get isRecommendedPost() {
    return this.post?.isRecommended;
  }

  public get postLabel() {
    return EventTypes.find((e) => this.post.postType === e.labelSingular);
  }

  public usePost() {
    this.createModule.setSelectedAsset(this.post.asset);
    this.createPostModule.setPostTemplate(this.post);
    this.$router.push({ path: '/create-post' });
  }
}
